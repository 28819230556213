body {
  line-height: 1;
}

.accordion {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.item {
  padding: 20px 24px;
  padding-right: 48px;
  cursor: pointer;
  background-color: transparent;
  border-bottom: 2px solid rgb(72, 72, 72);
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 24px;
  row-gap: 32px;
  align-items: center;
}

.number {
  font-size: clamp(14px, 8vw, 24px);
  font-weight: 500;
  font-family: sans-serif;
  color: var(--quaternary--);
}

.title {
  font-size: clamp(16px, 4vw, 30px);
  color: var(--quinary--);
  font-weight: 300px;
  font-family: var(--tertiary-font);
}

.date {
  font-size: clamp(40px, 5vw, 80px);
  font-weight: 300px;
  color: #343a40;
  font-family: sans-serif;
  display: flex;
  flex-direction: row-reverse;
}

.content-box {
  font-family: var(--tertiary-font);
  padding-bottom: 16px;
  line-height: 1.6;
}

.content-box ul {
  color: #868e96;
  margin-left: 16px;
  margin-top: 16px;
  gap: 12px;
}

.Accordion-Image {
  max-width: 450px;
  min-width: 100px;
  width: auto;
  height: auto;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

.Accordion-Image-2 {
  max-width: 450px;
  min-width: 100px;
  width: auto;
  height: auto;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}

.open {
  display: grid;
  grid-template-areas:
    "item-1 item-2 item-3"
    "item-4 item-4 item-4"
    "item-5 item-5 item-5";
  transform: 0.5s;
  border-top: 4px solid rgb(0, 191, 255);
  background-color: #343a40;
  color: white;
}

.open .number,
.open .title {
  transform: 0.5s;
  color: rgb(0, 191, 255);
}

.open .date {
  transform: 0.5s;
  color: rgb(0, 191, 255);
}

.open .number {
  grid-area: item-1;
}

.open .title {
  grid-area: item-2;
  margin: 0;
  padding: 0;
}

.open .date {
  grid-area: item-3;
}

.open .content-box {
  grid-area: item-4;
}

.Image-Wrapper {
  grid-area: item-5;
  display: flex;
  flex-direction: row;
  gap: 3%;
}

.open .month {
  grid-area: item-3;
  display: flex;
  font-size: clamp(14px, 3vw, 24px);
  font-family: sans-serif;
  justify-content: center;
  color: white;
  background-color: rgba(255, 255, 255, 0.306);
}

@media screen and (min-width: 800px) {
  .item:hover {
    transition: 0.5s;
    background-color: rgb(170, 234, 255);
    color: black;
    .title {
      color: rgb(0, 191, 255);
    }
    .date {
      color: rgb(0, 191, 255);
    }
  }
}
