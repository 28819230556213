* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

main {
  background-color: var(--primary--);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0% 10%;
}
