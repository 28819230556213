.About-Wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 15%;
}

.Story-Container {
    box-sizing: border-box;
    margin-right: 15%;
}

.About-Title {
    color: var(--tertiary--);
    font-family: var(--main-font);
    font-size: clamp(45px, 5vw, 60px);
}

.Subtitle-Container {
    display: flex;
    flex-direction: row;
    font-family: sans-serif;
    font-size: clamp(30px, 3vw, 35px);
    border-bottom: 2px solid rgb(152, 152, 152);
    padding-bottom: 2%;
}

.Content-Type {
    font-family: var(--secondary-font);
    color: var(--quaternary--);
}

.Content-Sub {
    color: var(--secondary--);
}

.Paragraph {
    color: var(--secondary--);
    font-family: var(--tertiary-font);
    font-size: clamp(18px, 5vw, 20px);
}

.Paragraph-1 {
    color: var(--quinary--);
}

.Image-Container {
    box-sizing: border-box;
}

.Image-Self {
    max-width: 250px;
    min-width: 60px;
    height: auto;
    display: block;
    border-radius: 10px;
}

.Skill-List {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    overflow: hidden;
    list-style: none;
    margin: 3% 0;
}

.Skill-List li {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    padding-left: 0px;
    margin-bottom: 12px;
    font-family: var(--tertiary-font);
    font-size: clamp(15px, 5vw, 20px);
    color: var(--quaternary--);
}

.list-icon {
    height: 15px;
    width: auto;
}
.College {
    text-decoration: none;
    color: var(--quaternary--);
    position: relative;
    z-index: 1;
}

.College::before {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    background-color: var(--quaternary--);
    bottom: -1px;
    left: 0;
    z-index: -1;
    transition: width 0.3s ease;
}

.College:hover::before {
    width: 100%;
}

@media screen and (max-width: 750px) {
    .About-Wrapper {
        display: flex;
        flex-direction: column;
        min-height: 90vh;
        padding-bottom: 20vh;
    }

    .Image-Self {
        width: 200px;
        height: auto;
        display: block;
        border-radius: 10px;
    }

    .Image-Container {
        margin-right: 15%;
        margin-top: 10%;
        padding-left: 15vw;
        box-sizing: border-box;
    }

    .Story-Container {
        margin-right: 0%;
    }
}
