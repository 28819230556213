.sidebar-list {
  position: fixed;
  left: 1vw;
  bottom: 3vh;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  margin: 0px;
  padding: 5px;
  list-style: none;
  border-bottom: 2px solid white;
}

.s {
  margin: 50%;
}

.icon-1 {
  width: 25px;
  height: 25px;
  margin: 0;
  padding: 0;
  transition: transform 1s ease;
}

.icon-3 {
  width: 35px;
  height: 35px;
  margin-bottom: 5px;
}

.icon-2-core {
  width: 35px;
  height: 35px;
  margin: 0;
  padding: 0;
  transition: transform 1s ease;
}

.icon-1:hover {
  fill: var(--quaternary--);
  transform: translate(0, -50%);
  transition: 0.3s ease;
}

.icon-3-core {
  transition: transform 1s ease;
}

.icon-3-core:hover {
  transform: translate(0, -50%);
  transition: 0.3s ease;
  .icon-3 {
    fill: var(--quaternary--);
  }
}

.icon-2-core:hover {
  transform: translate(0, -50%);
  transition: 0.3s ease;
  .icon-2 {
    stroke: var(--quaternary--);
  }
}

/* .rotated-text {
  transition: transform 1s ease;
  font-family: var(--tertiary-font);
  color: #6c757d;
  text-decoration: none;
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  transform-origin: center;
  white-space: nowrap;
}

.rotated-text:hover {
  transition: 0.3s ease;
  transform: rotate(-180deg) translateY(10%);
  color: var(--quaternary--);
} */

@media screen and (max-width: 1024px) {
  .sidebar-container {
    display: none;
  }
}
