.contact-wrapper {
  color: white;
  box-sizing: border-box;
  max-width: 1000px;
  margin: 0 auto;
}

.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 70vh;
}

.contact-subtitle {
  font-weight: 300;
  font-size: clamp(16px, 6vw, 22px);
  font-family: var(--tertiary-font);
  color: var(--senary--);
  padding: 1% 0%;
}

.contact-title {
  font-size: clamp(30px, 3vw, 60px);
  font-family: var(--main-font);
  padding: 1% 0%;
  color: var(---tertiary--);
}

.contact-content {
  line-height: 1.3;
  font-family: var(--secondary-font);
  color: var(--secondary--);
  text-align: center;
  max-width: 45vw;
  word-wrap: break-word;
  font-size: clamp(14px, 3vw, 18px);
  padding: 2% 0%;
}

.contact-button {
  margin-top: 5%;
  font-size: clamp(16px, 6vw, 25px);
  border: 2px solid var(--quaternary--);
  padding: 1.5% 2%;
  color: var(--quaternary--);
  background: none;
  cursor: pointer;
  font-family: var(--secondary-font);
  transition: 0.3s ease;
  text-decoration: none;
}

.contact-button:hover {
  background-color: var(--quaternary--);
  color: white;
}

.contact-credit {
  padding-top: 5px;
  display: flex;
  justify-content: center;
  justify-items: center;
  color: var(--secondary--);
  font-family: var(--secondary-font);
  font-size: 15px;
}

.bottom-bar {
  width: 75px;
  margin: 0 auto;
}
.bottom-bar-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media (max-width: 450px) {
  .contact-content {
    max-width: 325px;
  }
}
