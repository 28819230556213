.nav-wrapper2 {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  background-color: var(--primary--);
  justify-content: space-between;
}

.navbar li {
  box-sizing: content-box;
  display: inline-block;
  list-style: none;
  text-decoration: none;
  font-size: var(--fn-xl);
  font-weight: 700;
  font-family: var(--secondary-font);
  cursor: pointer;
  color: var(--secondary--);
  margin: 1rem;
}

.nav-logo {
  width: 100px;
  height: auto;
  margin-left: 1%;
}

.navbar li a:hover {
  color: var(--tertiary--);
}

.buttonContact {
  padding: 8px 10px;
  border: 2px solid var(--quaternary--);
  color: var(--quaternary--) !important;
}

.buttonContact:hover {
  border: 2px solid var(--tertiary--);
  color: var(--tertiary--) !important;
}

/* Below Are CSS for Mobile Device */

#mobile {
  display: none;
}
@media screen and (max-width: 769px) {
  .navbar {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: fixed;
    top: 50px;
    width: 100%;
    background: var(--primary--);
    transition: opacity 0.3s ease-in-out;
  }

  #mobile {
    display: flex;
    flex-direction: row-reverse;
    background: var(--primary--);
  }

  .navbar li {
    padding: 20px 100vh;
    color: var(--secondary--);
    margin: 0;
  }

  .navbar.active {
    display: flex;
  }

  #icon {
    font-size: 40px;
    color: var(--quaternary--);
    cursor: pointer;
    transition: transform 0.4s ease-in-out;
    align-self: flex-end;
    padding: 1rem;
    z-index: 999;
  }

  .buttonContact {
    border: 2px solid var(--secondary--);
    margin-bottom: 2px !important;
  }

  .nav-logo {
    z-index: 999;
  }
}
