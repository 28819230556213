@import url("https://fonts.googleapis.com/css2?family=Carrois+Gothic&family=Montserrat:wght@800&family=Orienta&display=swap");
:root {
  --primary--: #212529;
  --secondary--: #6c757d;
  --tertiary--: #f8f9fa;
  --quaternary--: #50c2ff;
  --quinary--: #adb5bd;
  --senary--: rgb(152, 152, 152);
  --septenary--: #343a40;
  --octonary--: rgb(72, 72, 72);

  --main-font: "Montserrat";
  --secondary-font: "Orienta", sans-serif;
  --tertiary-font: "Carrois Gothic", sans-serif;

  --fn-xxs: 12px;
  --fn-xs: 13px;
  --fn-sm: 14px;
  --fn-md: 16px;
  --fn-lg: 18px;
  --fn-xl: 20px;
  --fn-xxl: 22px;
  --fn-heading: 32px;
}

@keyframes wave {
  0%,
  20% {
    transform: rotate(30deg);
  }
  40% {
    transform: rotate(15deg);
  }
  60% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(30deg);
  }
}

#wave:hover {
  display: inline-block;
  font-size: 1em;
  animation: wave 1s infinite;
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:30px;'><text y='50%'>✋</text></svg>")
      16 0,
    auto;
}

.Intro-Container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 90vh;
  max-width: 1000px;
  margin: 0% auto;
}

.Intro-Container li {
  list-style-type: none;
  padding: 1.25rem 0 0 0;
}

.List-Container {
  margin-bottom: 3%;
}

.text-P {
  font-family: var(--main-font);
  font-size: clamp(45px, 6vw, 80px);
  color: var(--tertiary--);
}

.text-S {
  line-height: 1.2;
  font-weight: bold;
  max-width: 1050px;
  word-wrap: break-word;
  font-family: var(--secondary-font);
  font-size: clamp(25px, 3vw, 50px);
  color: var(--quinary--);
}

.text-T {
  font-family: var(--tertiary-font);
  margin-right: 5rem;
  max-width: 45vw;
  word-wrap: break-word;
  font-size: clamp(18px, 6vw, 25px);
  color: var(--secondary--);
}

#name {
  color: var(--quaternary--);
}

@keyframes scrollAnimation {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px); /* Adjust distance for scroll */
  }
}
.scroll-container {
  margin-top: 0%;
}
.scroll-icon {
  cursor: pointer;
  position: absolute;
  left: 50%;
  width: 38px;
  height: 68px;
  transform: translateX(-50%);
  animation: scrollAnimation 1.3s infinite cubic-bezier(0.4, 0, 0.2, 1);
  border: 2px solid var(--quaternary--);
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease;
}

.scroll-icon:hover {
  border: 2px solid white;
  .scroll-icon-core {
    stroke: white;
  }
}

@media (max-width: 450px) {
  .text-T {
    max-width: 350px;
  }

  .scroll-container {
    margin-top: 20%;
  }
}

@media (max-width: 390px) {
  .Intro-Container {
    margin-bottom: 40%;
  }
}
