.Project-Wrapper {
  max-width: 1000px;
  margin: 0 auto;
}

.Project-Container {
  display: block;
  box-sizing: border-box;
  margin-bottom: 5%;
}

.Project-Container h2 {
  padding-top: 2%;
  font-size: clamp(45px, 5vw, 80px);
  font-family: var(--main-font);
  margin-bottom: 2.5%;
  color: var(--tertiary--);
}

.Project-Container p {
  font-family: var(--tertiary-font);
  font-size: clamp(18px, 5vw, 20px);
  color: var(--secondary--);
  padding-bottom: 2%;
  margin-bottom: 5%;
  border-bottom: 2px solid rgb(152, 152, 152);
}

.Card-Wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
