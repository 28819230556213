*,
*:before,
*:after {
  box-sizing: border-box;
}

.button-container {
  display: flex;
}

.button-1 {
  display: block;
  padding: 12px 15px;
  background: var(--quaternary--);
  font-family: var(--secondary-font);
  font-size: clamp(10px, 5vw, 20px);
  color: black;
  text-decoration: none;
  text-align: center;
  position: relative;
  overflow: hidden;
  text-transform: uppercase;
  transition: all 0.35s;
  margin-top: 15px;
  margin-right: 10px;
  border: 2px solid var(--quaternary--);
}

.button-2 {
  display: block;
  padding: 12px 15px;
  background: transparent;
  font-family: var(--secondary-font);
  font-size: clamp(10px, 5vw, 20px);
  color: var(--quaternary--);
  text-decoration: none;
  text-align: center;
  position: relative;
  overflow: hidden;
  text-transform: uppercase;
  transition: all 0.35s;
  margin-top: 15px;
  margin-right: 10px;
  border: 2px solid var(--quaternary--);
}

.button-1:hover {
  color: var(--tertiary--);
  border: 2px solid var(--tertiary--);
  font-weight: 500;
  cursor: pointer;
}

.button-2:hover {
  color: var(--tertiary--);
  border: 2px solid var(--tertiary--);
  font-weight: 500;
  cursor: pointer;
}
.button-1:hover:before,
.button-1:hover:after {
  top: 0;
}

/* effects */
.effect:after {
  content: "»";
  position: absolute;
  opacity: 0;
  right: -20px;
  transition: 0.5s;
}

.effect:hover {
  padding-right: 24px;
  padding-left: 8px;
  cursor: pointer;
}

.effect:hover:after {
  opacity: 1;
  right: 10px;
}

@media screen and (max-width: 440px) {
  .button {
    font-size: clamp(15px, 6vw, 18px);
  }
}
